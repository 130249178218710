import React from "react";
import { Layout, Typography } from "antd";
import moment from "moment";

const { Footer } = Layout;

export default function Foot() {
    return (
        <Footer style={{ textAlign: "center" }}>
            <Typography.Title color="secondary" style={{ fontSize: "0.75rem" }}>
                {/* {`E-KYC ${moment().year()} Created by SSLWireless`} */}
                Offshore Banking Developed By SSL Wireless
            </Typography.Title>
        </Footer>
    );
}
