import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cookies from "js-cookie";
import {
  Layout,
  Typography,
  Table,
  Tag,
  Skeleton,
  Card,
  Button,
  Spin,
} from "antd";
import { SettingTwoTone } from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";

import { getData } from "../../../utils/api-service";
import { FORM_LIST } from "../../../utils/api-list";
import dummyProfile from "../../../assets/images/profile-image.jpg";
import "./agent-dashboard.scss";

const AgentDashboard = ({ match }) => {
  const router = useHistory();
  const [data, setData] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    (async () => {
      const res = await getData(FORM_LIST);
      if (res?.data?.form_complete_value) setData(res.data);
      if (cookies.get("token") && cookies.get("agent")) {
        const user_info = JSON.parse(cookies.get("agent"));
        setUserInfo(user_info);
        setLoader(false);
      }
    })();
  }, []);

  const columns = [
    {
      title: "Type",
      dataIndex: "form_type",
      key: "form_type",
    },
    {
      title: "Tracking ID",
      dataIndex: "request_tracking_uid",
      key: "request_tracking_uid",
    },
    {
      title: "Creation date",
      dataIndex: "application_start_date",
      key: "application_start_date",
      render: (text, record) => {
        return moment(record.application_start_date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return <Tag>{record.status}</Tag>;
      },
    },
    {
      key: "actions",
      render: (text, record) => {
        return (
          <Button
            onClick={() =>
              router.push(
                `/${match?.params?.userType}/dashboard/user-info/${record.request_tracking_uid}`
              )
            }
            type="primary"
          >
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <Layout.Content>
      {loader ? (
        <div className="agent-dashboard-container">
          <Spin
            indicator={<SettingTwoTone style={{ fontSize: 50 }} spin />}
            style={{ marginTop: "150px" }}
            tip="Loading...."
          />
        </div>
      ) : (
        <div className="agent-dashboard-container">
          <div className="agent-profile">
            <Avatar size={128} src={dummyProfile} />
            <div className="identity">
              <Typography.Title level={6}>
                {userInfo?.name || "John doe"}
              </Typography.Title>
              <Typography.Text>
                Branch Name : {userInfo?.branch}
              </Typography.Text>
              <br />
              {/* <Typography.Text>
              Organization Name : {data?.agent_organization || "Lorem Ipsum"}
            </Typography.Text> */}
            </div>
          </div>
          <div className="data-table">
            {data && (
              <Card className="agent-table-card">
                <Table
                  className="agent-table"
                  pagination={false}
                  dataSource={data.form_complete_value}
                  columns={columns}
                />
              </Card>
            )}
            {!data && <Skeleton active />}
          </div>
        </div>
      )}
    </Layout.Content>
  );
};

export default AgentDashboard;
