import React, { useEffect, useState } from "react";
import {
  Layout,
  Card,
  Typography,
  Button,
  message,
  Spin,
  Dropdown,
  Menu,
} from "antd";
import {
  SettingTwoTone,
  CameraTwoTone,
  SelectOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import _ from "lodash";

import Steps from "../../../components/Steps";
import NIDVerified from "../NIDVerified";
import { postData } from "../../../utils/api-service";
import { UPLOAD_DOCUMENTS } from "../../../utils/api-list";
import {
  dateFormatter,
  dataURItoBlob,
  isSectionSkipable,
  scannerErrorCodeToString,
  getNIDScore,
  convertDateForPicker,
} from "../../../utils/util-functions";
import { scannerConfigs } from "../../../utils/constants";
import DefaultNID from "../../../assets/images/nid.png";
import "./UploadForm.scss";
const { Content } = Layout;

const UploadForm = ({ formSection, userType, nextForm, sequence }) => {
  const [nidFront, setNidFront] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [selectedImages, setSelectedImages] = useState({});
  const [loading, setLoading] = useState();
  const [subComplete, setSubComplete] = useState(false);
  const [showCameraSection, setShowCameraSection] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [camIndex, setCamIndex] = useState({});
  const [uploadList, setUploadList] = useState([]);
  const [camImage, setCamImage] = useState(null);

  const fileHandler = (event, index, field) => {
    const { id, files, name } = event.target;

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedImages((sli) => ({ ...sli, [index]: reader.result }));
        if (name === "nid_image") setNidFront(reader.result);
        if (name === "user_photo") setUserPhoto(reader.result);
      }
    };
    if (event.target.files[0] && event.target.id) {
      reader.readAsDataURL(files[0]);

      let matchedIndex = _.findIndex(uploadList, { field_id: id });
      let tempList = uploadList;

      if (matchedIndex !== -1) {
        tempList[matchedIndex].field_id = id;
        tempList[matchedIndex].field_value = files[0];
        tempList[matchedIndex].is_required = !!field.is_required;

        setUploadList(tempList);
      } else {
        setUploadList((prevState) => [
          ...prevState,
          {
            field_id: id,
            field_value: files[0],
            is_required: !!field.is_required,
          },
        ]);
      }
    } else return;
  };

  const clearPreview = () => {
    setSubComplete(false);
  };

  const cameraSection = () => {
    return (
      <div>
        <Typography.Title level={3}>Take Selfie</Typography.Title>
        {showCamera && (
          <React.Fragment>
            <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri);
              }}
            />
            <Button icon={<CloseCircleOutlined />} onClick={() => cameraOff()}>
              Close
            </Button>
          </React.Fragment>
        )}
        {camImage && (
          <div className="space">
            <img src={camImage} alt="Cam" />
            <br />
            <br />
            <Button
              onClick={() => acceptCamInput()}
              style={{ marginBottom: "10px" }}
              type="primary"
              icon={<SelectOutlined />}
            >
              Accept
            </Button>
            <Button onClick={() => takeAnotherPhoto()} icon={<CameraTwoTone />}>
              Take Another
            </Button>
          </div>
        )}
      </div>
    );
  };

  const cameraOn = (index, id, field) => {
    setCamImage(null);
    setSelectedImages((sli) => ({ ...sli, [index]: "" }));
    setShowCameraSection(true);
    setShowCamera(true);
    setCamIndex(
      (prev) => prev,
      (camIndex.index = index),
      (camIndex.id = `${id}`),
      (camIndex.is_required = !!field.is_required)
    );
  };

  const cameraOff = () => {
    setShowCameraSection(false);
    setShowCamera(false);
  };

  const takeAnotherPhoto = () => {
    setShowCamera(true);
    setCamImage(null);
  };

  const handleTakePhoto = (dataUri) => {
    setCamImage(dataUri);
    setShowCamera(false);
  };

  const acceptCamInput = () => {
    setSelectedImages((sli) => ({ ...sli, [camIndex.index]: camImage }));

    let matchedIndex = _.findIndex(uploadList, { field_id: camIndex.id });
    let tempList = uploadList;

    let blob = dataURItoBlob(camImage);
    blob.name = `${Math.floor(Date.now() / 1000)}.png`;

    if (matchedIndex !== -1) {
      tempList[matchedIndex].field_id = camIndex.id;
      tempList[matchedIndex].field_value = blob;

      setUploadList(tempList);
      cameraOff();
    } else {
      setUploadList((prevState) => [
        ...prevState,
        {
          field_id: camIndex.id,
          field_value: blob,
          is_required: camIndex.is_required,
        },
      ]);
      cameraOff();
    }
  };

  const checkRequiredFields = () => {
    let requiredFields = [];
    _.map(formSection.form_section_fields, (field) => {
      if (!!field.is_required) {
        requiredFields.push(`${field.id}`);
      }
    });

    if (_.isEmpty(uploadList)) {
      if (!_.isEmpty(requiredFields)) {
        return true;
      } else {
        return false;
      }
    } else {
      let temp = [];
      _.map(requiredFields, (id) => {
        temp.push(_.findIndex(uploadList, ["field_id", id]));
      });
      return temp.includes(-1);
    }
  };

  const CallSGIFPGetData = (index, id, field) => {
    setSelectedImages((sli) => ({ ...sli, [index]: "" }));

    var uri = "https://localhost:8443/SGIFPCapture";
    var xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = async () => {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        let fpobject = await JSON.parse(xmlhttp.responseText);
        SuccessFunc(fpobject, index, id, field);
      } else if (xmlhttp.status === 404) {
        message.error(
          `Error ${xmlhttp.status}: Please Check if the device is running`
        );
      }
    };

    message.info(`Give your finger print while the device is running`);
    let params = `Timeout=${scannerConfigs.timeOut}&Quality=${scannerConfigs.quality}&licstr=${scannerConfigs.secugen_lic}&templateFormat=${scannerConfigs.templateFormat}&imageWSQRate=${scannerConfigs.imageWSQRate}`;
    xmlhttp.open("POST", uri, true);
    xmlhttp.send(params);
    xmlhttp.onerror = () => {
      message.error(
        `Error ${xmlhttp.statusText}: Please Check if the device is running`
      );
    };
  };

  const SuccessFunc = (result, index, id, field) => {
    if (result.ErrorCode === 0) {
      if (result != null && result.BMPBase64.length > 0) {
        let display = `data:image/png;base64,${result.BMPBase64}`;
        let blob = dataURItoBlob(display);
        blob.name = `${Math.floor(Date.now() / 1000)}.png`;

        setSelectedImages((sli) => ({ ...sli, [index]: display }));

        let matchedIndex = _.findIndex(uploadList, { field_id: id });
        let tempList = uploadList;
        if (matchedIndex !== -1) {
          tempList[matchedIndex].field_id = `${id}`;
          tempList[matchedIndex].field_value = blob;
          tempList[matchedIndex].is_required = !!field.is_required;

          setUploadList(tempList);
        } else {
          setUploadList((prevState) => [
            ...prevState,
            {
              field_id: `${id}`,
              field_value: blob,
              is_required: !!field.is_required,
            },
          ]);
        }
      }
    } else {
      message.error(
        `Error ${result.ErrorCode}: ${scannerErrorCodeToString(
          result.ErrorCode
        )}`
      );
    }
  };

  const submit = async () => {
    setLoading(true);

    if (checkRequiredFields()) {
      message.error("please upload necessary documents");
      setLoading(false);
      return;
    }

    if (_.isEmpty(uploadList) && isSectionSkipable(formSection)) {
      setLoading(false);
      nextForm();
      return;
    }

    let User = JSON.parse(localStorage.getItem("User"));
    let uploadData = new FormData();
    uploadData.append("form_id", formSection.form_id);
    uploadData.append("request_tracking_uid", User.request_tracking_uid);
    if (!!formSection.should_validated) {
      uploadData.append("verification_type", formSection.verification_type);
    }
    uploadList.forEach((item, index) => {
      uploadData.append(`form[${index}][section_id]`, formSection.id);
      uploadData.append(`form[${index}][field_id]`, item.field_id);
      uploadData.append(`form[${index}][field_value]`, item.field_value);
    });

    let query = UPLOAD_DOCUMENTS(userType);
    let response = await postData(query, uploadData);

    if (response && response.code === 200) {
      setLoading(false);

      User.date_of_birth = response?.data?.birthday
        ? convertDateForPicker(response.data.birthday)
        : null;
      User.passport_no = response?.data?.passport_num;
      User.name_en = response?.data?.passport_name;
      User.father_name_en = response?.data?.father_name;
      User.mother_name_en = response?.data?.mother_name;

      localStorage.setItem("User", JSON.stringify(User));

      /* 
      User.nid_verification = response.data.nid_verification
        ? response.data.nid_verification.status
        : "";
      User.nid_photo = nidFront;
      User.user_photo = userPhoto;
      response?.data?.prefilled_data.forEach((data) => {
        User[data.title] = data?.value;
        User.fixed[`${data.title}`] = data?.value;
        if (data.title.includes("birth") || data?.title.includes("date")) {
          User.fixed[`${data.title}`] = dateFormatter(data?.value);
          User[data.title] = dateFormatter(data?.value);
        }
      });
      User.compare_response = response?.data?.compare_response || null;
      User.fixed.permanent_address_full_en = response?.data?.nid_response?.data?.permanent_address_en?.fullAddress || null;
      User.fixed.permanent_address_full_bn = response?.data?.nid_response?.data?.permanent_address?.fullAddress || null;
      User.fixed.present_address_full_en = response?.data?.nid_response?.data?.present_address_en?.fullAddress || null;
      User.fixed.present_address_full_bn = response?.data?.nid_response?.data?.present_address?.fullAddress || null;
      User.fixed.blood_group = response?.data?.nid_response?.data?.blood_group || null;
      User.nid_score = getNIDScore(User?.compare_response?.document_resemblance_score, User?.compare_response?.object_detection_score);
      User.nid_image_match_score = response?.data?.compare_image_with_nid?.similarity || 0;
      User.nid_verification_status = response?.data?.compare_image_with_nid?.verification_status;


      localStorage.setItem("User", JSON.stringify(User));
      message.success(response.message[0]);
      setLoading(false);
      if (!!formSection.is_preview_on) {
        setSubComplete(true);
      } else {
        nextForm();
      } */
      nextForm();
    } else {
      message.error("something went wrong please try again");
      setLoading(false);
    }
  };

  return (
    <Content>
      {!subComplete ? (
        <div className="upload-container">
          <Steps currentPage={sequence} />
          {/* <BlinkAndHeadTracking /> */}
          {!showCameraSection && (
            <div className="title">
              <Typography.Title level={3}>{formSection.name}</Typography.Title>
              <p>
                {formSection.name === "Signature Upload"
                  ? "Please upload your signature"
                  : "Please upload your passport-size photo and passport"}
              </p>
            </div>
          )}
          {showCameraSection ? (
            cameraSection()
          ) : (
            <div className="upload-section">
              {!loading &&
                formSection.form_section_fields &&
                formSection.form_section_fields.map((field, index) => {
                  return (
                    <Card key={index}>
                      <Typography.Title level={5}>
                        {field.label}{" "}
                        {!!field.is_required && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </Typography.Title>
                      <Typography.Text>
                        {field.file_source === "camera"
                          ? "Select an image (max 1MB)"
                          : field.file_source === "fingerprint"
                          ? "provide your finger-print"
                          : "Select an image (max 1MB)"}
                      </Typography.Text>
                      <div className="space">
                        <img src={selectedImages[index] || DefaultNID} alt="" />
                      </div>
                      <br />
                      {field.file_source === "camera" ? (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                style={{ textAlign: "center" }}
                                icon={<CameraTwoTone />}
                                key="1"
                                onClick={() => {
                                  cameraOn(index, field.id, field);
                                }}
                              >
                                Take Photo
                              </Menu.Item>
                              <Menu.Item
                                style={{ textAlign: "center" }}
                                key="2"
                              >
                                <input
                                  type="file"
                                  name={field.field_name}
                                  id={field.id}
                                  accept="image/*"
                                  onChange={(e) => {
                                    fileHandler(e, index, field);
                                  }}
                                />
                              </Menu.Item>
                            </Menu>
                          }
                          placement="topCenter"
                        >
                          <label className="upload-btn">Select</label>
                        </Dropdown>
                      ) : field.file_source === "fingerprint" ? (
                        <label
                          className="upload-btn"
                          onClick={() => {
                            CallSGIFPGetData(index, field.id, field);
                          }}
                        >
                          Start Scan
                        </label>
                      ) : (
                        <label className="upload-btn">
                          <input
                            type="file"
                            name={field.field_name}
                            id={field.id}
                            accept="image/*"
                            onChange={(e) => {
                              fileHandler(e, index, field);
                            }}
                          />
                          Upload
                        </label>
                      )}
                      <br />
                    </Card>
                  );
                })}
              {loading && (
                <div className="image-loader">
                  <Spin
                    indicator={<SettingTwoTone style={{ fontSize: 60 }} spin />}
                  />
                  <div className="space" />
                  <Typography.Title level={2}>
                    {
                      isSectionSkipable(formSection) && _.isEmpty(uploadList)
                        ? "Skipping to next form"
                        : "Uploading..."
                      //: "NID & image verification process is running"
                    }
                  </Typography.Title>
                </div>
              )}
            </div>
          )}
          {!showCameraSection && (
            <Button
              size="large"
              block
              loading={loading}
              type="primary"
              style={{ margin: "5px" }}
              onClick={submit}
            >
              &nbsp;
              {isSectionSkipable(formSection) && _.isEmpty(uploadList)
                ? "Skip"
                : "Submit"}
            </Button>
          )}
          <br />
        </div>
      ) : (
        <NIDVerified
          clearPreview={() => clearPreview()}
          nextForm={() => nextForm()}
          sequence={sequence}
        />
      )}
    </Content>
  );
};

export default UploadForm;
