import React from 'react';
import { Result, Button } from 'antd';

const ErrorPage = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<a style={{color: "white"}} href="/"><Button type="primary"> Back Home </Button></a>}
        />
    )
}

export default ErrorPage
