import React, { useRef, useState } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  Col,
  DatePicker,
  Divider,
  Space,
  Button,
} from "antd";
import _ from "lodash";
import moment from "moment";

import { dateFormat } from "../../../utils/constants";
import "./FormField.scss";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const FormField = ({
  field,
  index,
  User,
  fileHandle,
  style,
  formSection,
  setSelectedForm,
  isOptionOther,
}) => {
  const [items, setItems] = useState(["jack", "lucy"]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e, fieldName) => {
    e.preventDefault();
    setName("");
    setSelectedForm((prevForm) => {
      // Create a copy of the previous state
      const updatedForm = { ...prevForm };

      // Find the field with the given fieldName
      const field = updatedForm.form_section_fields.find(
        (field) => field.field_name === fieldName
      );

      // Check if the field exists and has an options array
      if (field && Array.isArray(field.options)) {
        // Check if the value already exists in the options
        const valueExists = field.options.some(
          (option) => option.value === name
        );

        if (!valueExists) {
          // Add the new value as an option
          field.options.push({ key: name, value: name });
          console.log(`Added "${name}" to the options of "${fieldName}"`);
        } else {
          console.log(
            `"${name}" already exists in the options of "${fieldName}"`
          );
        }
      } else {
        console.error(
          `Field "${fieldName}" does not exist or has no options array`
        );
      }

      return updatedForm;
    });
  };
  const renderInputByType = (field) => {
    if (
      (field.data_type === "text" || field.data_type === "number") &&
      field.field_type === "input"
    ) {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          placeholder={field.placeholder || ""}
          initialValue={User[`${field.field_name}`] || field?.field_value}
          rules={[
            {
              required: !!field.is_required,
              message: "This field is required",
            },
          ]}
          style={style}
        >
          <Input disabled={!!field.is_readonly} />
        </Form.Item>
      );
    } else if (!_.isEmpty(field.options) && field.field_type === "select") {
      return (
        <Form.Item
          label={field.label}
          id={field.id}
          key={index}
          name={field.field_name}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "Please select an option",
            },
          ]}
          style={style}
        >
          <Select
            placeholder={field.placeholder || `Select ${field.label}`}
            {...(isOptionOther && {
              dropdownRender: (menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      value={name}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      onClick={(e) => addItem(e, field.field_name)}
                     icon={<PlusOutlined />}
                    >
                      Add item
                    </Button>
                  </Space>
                </>
              ),
            })}
          >
            {field.options?.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (field.data_type === "file") {
      return (
        <Form.Item
          label={field.label}
          id={field.id}
          key={index}
          name={field.field_name}
          rules={[
            {
              required: !!field.is_required,
              message: "Please Upload a valid file",
            },
          ]}
          style={style}
        >
          <input
            type="file"
            name={field.field_name}
            id={field.id}
            onChange={fileHandle}
          />
        </Form.Item>
      );
    } else if (field.data_type === "text" && field.field_type === "textarea") {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "This field is required",
            },
          ]}
          style={style}
        >
          <Input.TextArea disabled={!!field.is_readonly} />
        </Form.Item>
      );
    } else if (field.field_type === "input" && field.data_type === "radio") {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "Please select an option",
            },
          ]}
          style={style}
        >
          <Radio.Group>
            {field.options?.map((option, index) => (
              <Radio style={{ display: "flex" }} key={index} value={option.key}>
                {option.value}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    } else if (field.data_type === "date" && field.field_type === "input") {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          initialValue={
            User[`${field.field_name}`]
              ? moment(User[`${field.field_name}`], dateFormat)
              : null
          }
          rules={[
            {
              required: !!field.is_required,
              message: "Please select an option",
            },
          ]}
          style={style}
        >
          <DatePicker disabled={!!field.is_readonly} format={dateFormat} />
        </Form.Item>
      );
    }
  };

  return (
    <Col key={index} md={8} xs={24}>
      {renderInputByType(field)}
    </Col>
  );
};

export default FormField;
