const TxnProfileEnum = Object.freeze({
  deposits: "Deposits",
  withdrawals: "Withdrawals",
  probable_monthly_nod: "Probable Monthly Number of Deposits",
  monthly_total_aod: "Monthly Total Amount of Deposits",
  maximum_amount_osd: "Maximum Amount of Single Deposit",
  cash_deposit: "Cash Deposit (including Online/ATM)",
  deposit_through_transfer_or_instruments:
    "Deposit Through Transfer/ Instruments",
  foreign_inward_remittance: "Foreign Inward Remittance",
  export_proceeds: "Export Proceeds",
  deposit_or_transfer_from_bo_account: "Deposit/Transfer from BO Account",
  cash_withdrawals: "Cash Withdrawals (including Online/ATM)",
  withdrawals_through_transfer_or_instruments:
    "Withdrawals Through Transfer/Instruments",
  withdrawal_of_foreign_outward_remittance:
    "Withdrawal of Foreign Outward Remittance",
  import_payment: "Import Payment",
  deposit_or_transfer_to_bo_account: "Deposit/Transfer to Bo Account",
  others: "Others (Specify)...",
  obu: "OBU",
  ib: "IB",
});

export default TxnProfileEnum;
