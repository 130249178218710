import React from "react";
import { Switch } from "react-router-dom";
import { ProtectedRoute } from "../../components/Protected";

import AccountTypes from "../AccountTypes";
import AgentDashboard from "./AgentDashboard";
import UserDetails from "./UserEntryDetails";
import Application from "../Application";

const Agent = () => {
  const routes = [
    { id: 1, path: "/:userType/application-form", comp: Application },
    { id: 2, path: "/:userType/dashboard", comp: AgentDashboard },
    { id: 3, path: "/:userType/dashboard/user-info/:details", comp: UserDetails },
    { id: 4, path: "/:userType/:accountType", comp: AccountTypes },
  ];

  return (
    <Switch>
      {routes &&
        routes.map((route) => {
          return (
            <ProtectedRoute
              exact
              key={route.id}
              path={route.path}
              component={route.comp}
              type="agent"
            />
          );
        })}
    </Switch>
  );
};

export default Agent;
