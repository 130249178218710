import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Layout,
  Form,
  Input,
  Button,
  Card,
  Typography,
  message,
  Tooltip,
  Row,
  Col,
  Spin,
} from "antd";
import OtpTimer from "otp-timer";
import { getData, postData } from "../../../utils/api-service";
import {
  DRAFT_FORM_DATA,
  GENERATE_TRACKING_ID,
  GET_FORM_FIELDS,
  SEND_OTP,
  VERIFY_OTP,
} from "../../../utils/api-list";
import otp_img from "../../../assets/icon/send_otp.png";
import "./MobileVerification.scss";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;

const MobileVerification = (props) => {
  const {
    match: { params },
  } = props;
  const [otpSent, setOtpSent] = useState(false);
  const [otpCountdown, setOtpCountdown] = useState(0);
  const [contactValue, setContactValue] = useState("");
  const [loading, setLoading] = useState();
  const [draftFormData, setDraftFormData] = useState(null);
  const router = useHistory();
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const subAccountType = queryParams.get("sub_account_type");
  const accountType = queryParams.get("type");

  const getFieldNameValueObject = (data) => {
    console.log({ data });
    const result = {};

    // Iterate through the form sections
    data.form_section.forEach((section) => {
      if (section.form_field) {
        // Iterate through form fields in each section
        section.form_field.forEach((field) => {
          result[field.field_name] = field.input_data || null; // Assign input_data or null if undefined
        });
      }
    });

    return result;
  };

  function extractNomineeData(formData) {
    const nomineeSections = formData.form_section.filter((section) =>
      section.name.includes("Nominee Information")
    );

    const formattedData = {};

    nomineeSections.forEach((section, index) => {
      const nomineeData = {};
      section.form_field.forEach((field) => {
        nomineeData[field.field_id] = field.input_data || "";
      });
      formattedData[index] = nomineeData;
    });

    return formattedData;
  }

  const getDraftData = async (contact, contactMethod, subAccountType) => {
    let payload;
    if (contactMethod === "email") {
      payload = {
        email: contact,
        platform_type: "WEB",
        sub_account_type: subAccountType,
      };
    } else {
      payload = {
        phone: contact,
        platform_type: "WEB",
        sub_account_type: subAccountType,
      };
    }
    let response = await postData(DRAFT_FORM_DATA, payload, null, false);
    console.log("draft response: ", response);
    return response;
  };

  const submit = async (values) => {
    const contactMethod = _isValidMobile(values.contact)
      ? "mobile_no"
      : _isValidEmail(values.contact)
      ? "email"
      : null;

    if (!contactMethod) {
      message.error("Please enter a valid mobile number or email address");
      return;
    }

    if (!otpSent) {
      setLoading(true);

      let contactData = new FormData();
      contactData.append("mobile_no", values.contact); // Using mobile_no as parameter name

      let response = await postData(SEND_OTP, contactData, false);
      if (response && response.code === 200) {
        setContactValue(values.contact);
        setOtpCountdown(58);
        setOtpSent(true);
        message.warning(response.message[0]);
      }
      setLoading(false);
    } else {
      setLoading(true);
      if (values.otp.length !== 6) {
        message.error("The verification code must be 6 digits");
        setLoading(false);
        return;
      }

      let contactData = new FormData();
      contactData.append("mobile_no", values.contact); // Using mobile_no as parameter name
      contactData.append("verification_code", values.otp);

      let response = await postData(VERIFY_OTP, contactData, false);

      let User = JSON.parse(localStorage.getItem("User"));
      User.phone_number = values.mobile_no;
      User.fixed.phone_number = values.mobile_no;
      User.client_session_key = response?.data?.client_session_key || null;
      User.mobile_verified = true;
      localStorage.setItem("User", JSON.stringify(User));
      message.success(response.message[0]);
      if (response && response.code === 200) {
        let draftData = await getDraftData(
          values.contact,
          contactMethod,
          subAccountType
        );
        if (draftData?.code === 200 && draftData?.data !== 423) {
          setDraftFormData(draftData?.data);
          // Generate the object
          const fieldNameValueObject = getFieldNameValueObject(draftData?.data);

          User.request_tracking_uid = draftData?.data?.request_tracking_uid;
          User.form_id = draftData?.data.form_id;

          localStorage.setItem(
            "User",
            JSON.stringify({ ...User, ...fieldNameValueObject })
          );

          console.log("draftData", fieldNameValueObject);

          const nomineeData = extractNomineeData(draftData?.data);
          localStorage.setItem(
            "nominee_cache_value",
            JSON.stringify(nomineeData)
          );
          console.log("cached", { nomineeData });
        }

        if (!!subAccountType) {
          // next();
          fetchForms(8);
          // router.push(
          //   `/user/${User.account_type}?sub_account_type=${subAccountType}`
          // );
        } else {
          router.push(`/user/${User.account_type}`);
        }
      } else if (
        response &&
        (response.code === "E002" || response.code === "E003")
      ) {
        message.error(response.message[0]);
      }
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    const contactMethod = _isValidMobile(contactValue)
      ? "mobile_no"
      : _isValidEmail(contactValue)
      ? "email"
      : null;

    if (!contactMethod) {
      message.error("Please enter a valid mobile number or email address");
      return;
    }

    let contactData = new FormData();
    contactData.append("mobile_no", contactValue); // Using mobile_no as parameter name

    let response = await postData(SEND_OTP, contactData, false);
    if (response && response.code === 200) {
      setOtpCountdown(58);
      setOtpSent(true);
      message.warning(response.message[0]);
    }
  };

  const _isValidMobile = (mobile) => {
    const pattern = /(^(\+88|0088)?(01){1}[13456789]{1}(\d){8})$/;
    return pattern.test(mobile);
  };

  const _isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  //Extra codes to skip Regular Ekyc and Simplified Ekyc options

  const [currentOptions, setCurrentOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    id: 8,
    account_type: 1,
    type_code: "savings_account",
    title: "Regular eKyc >= 10 Lakh",
    icon: "images/formSettings/icon/1602479227.png",
    color: null,
    description: null,
    is_active: 1,
    form: [
      {
        id: 8,
        form_type_code: "savings_account",
        kyc_type: "simplified",
      },
      {
        id: 11,
        form_type_code: "savings_account",
        kyc_type: "regular",
      },
    ],
  });
  const [formOptions, setFormOptions] = useState([]);
  const [selectedFormOption, setSelectedFormOption] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  // const router = useHistory();
  // const location = useLocation();

  // Parse the query string
  // const queryParams = new URLSearchParams(location.search);
  const sub_account_type = queryParams.get("sub_account_type");

  useEffect(() => {
    // initializeState(accountType);
    console.log("Initializing", accountType);
  }, []);

  const fetchForms = async (form_id) => {
    setLoader(true);

    let query = `${GET_FORM_FIELDS(form_id, "user", sub_account_type)}`;

    let data = new FormData();
    data.append("form_id", form_id);
    data.append("platform_type", "web");

    let response1 = await postData(query, data);

    if (response1 && response1.code === 200) {
      let response = {
        ...response1,
        data: {
          ...response1.data,
          form_section: response1.data.form_section.map((item) => {
            if (
              item.name === "Personal Information" ||
              item.name === "Present Address" ||
              item.name === "Permanent Address"
            ) {
              return {
                ...item,
                section_type: "info_form",
              };
            } else if (item.name === "Signature Upload") {
              return {
                ...item,
                section_type: "uploader_form",
              };
            } else if (item.name === "Transaction Profile") {
              return {
                ...item,
                section_type: "basic_form",
              };
            } else return item;
          }),
        },
      };

      console.log("response mobile verification", { response }, { response1 });

      const nomineeDataArray = response?.data?.form_section?.filter(
        (sectionItem) => sectionItem.section_type === "both_form"
      );
      const personalInfoDataArray = response?.data?.form_section?.filter(
        (sectionItem) => sectionItem.section_type === "info_form"
      );

      const completeFormData = [
        ...response?.data?.form_section?.filter(
          (sectionItem, index) => sectionItem.is_show_on_tab
          // &&
          //   sectionItem?.name !== "Transaction Profile"
        ),
      ];

      // Insert at the 5th index
      // completeFormData.splice(2, 0, transactionProfile);

      // console.log("completeFormData ex", completeFormData)

      localStorage.setItem("complete_form", JSON.stringify(completeFormData));
      localStorage.setItem("nominee_data", JSON.stringify(nomineeDataArray));
      localStorage.setItem(
        "personal_info_data",
        JSON.stringify(personalInfoDataArray)
      );
      console.log("draftFormData", draftFormData);
      let User = JSON.parse(localStorage.getItem("User"));
      if (User.request_tracking_uid) {
        User.account_type = null;
        localStorage.setItem("User", JSON.stringify(User));
        router.push(
          `/${"user"}/application-form?type=${accountType}&sub_account_type=${sub_account_type}`
        );
        setLoader(false);
      } else {
        fetchTrackingId(form_id);
      }
    } else {
      setLoader(false);
      message.error("Something went wrong please try again");
    }
  };

  const isEmail = (contactValue) => {
    // Define a regular expression for a simple email pattern
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(contactValue);
  };

  const fetchTrackingId = async (form_id) => {
    let query = `${GENERATE_TRACKING_ID("user")}`;

    let trackingData = new FormData();
    trackingData.append("form_id", form_id);
    trackingData.append("platform_type", "web");
    if (isEmail(contactValue)) {
      trackingData.append("email", contactValue);
    } else trackingData.append("phone", contactValue);

    let response = await postData(query, trackingData);

    if (response && response.code === 200) {
      let User = JSON.parse(localStorage.getItem("User"));
      User.request_tracking_uid = response.data.request_tracking_uid;
      User.form_id = response.data.form_id;
      User.account_type = null;
      localStorage.setItem("User", JSON.stringify(User));
      router.push(
        `/${"user"}/application-form?type=${accountType}&sub_account_type=${sub_account_type}`
      );
      setLoader(false);
    } else {
      message.error("Something went wrong");
      setLoader(false);
    }
  };

  const initializeState = (accountType) => {
    let options = JSON.parse(localStorage.getItem(`${accountType}`));
    if (_.isEmpty(options)) {
      return (window.location.href = "/");
    }
    setCurrentOptions(options.form_type);
    setPageLoader(false);
  };

  const onChangeRadio = (event) => {
    console.log("onChangeRadio", event.target.value);
    setSelectedValue(event.target.value);
  };

  const formOptionChange = (event) => {
    setSelectedValue("");
    setSelectedFormOption(event.target.value);
  };

  const next = () => {
    if (_.isEmpty(selectedValue)) {
      message.error("Please select an Account Type");
      return;
    }
    if (params.userType === "user") {
      if (_.find(selectedValue.form, ["kyc_type", "simplified"])) {
        let form_id = _.find(selectedValue.form, ["kyc_type", "simplified"]).id;
        fetchForms(form_id);
      } else {
        message.error("No form avalible for this Account Type");
      }
    } else if (params.userType === "agent") {
      if (!_.isEmpty(selectedValue.form)) {
        setFormOptions(selectedValue.form);
      } else {
        message.error("No form avalible for this Account Type");
      }
    }
  };

  const agentNext = () => {
    if (_.isEmpty(selectedFormOption)) {
      message.error("Please select a Form Type");
      return;
    }
    fetchForms(selectedFormOption.id);
  };

  const clearFormType = () => {
    setFormOptions([]);
  };

  const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Content>
      <div className="container">
        {accountType !== "offshore_account" ? (
          <Row
            justify="center"
            align="middle"
            style={{
              height: "100vh",
            }}
          >
            <Col>
              <Typography.Title level={3} style={{ marginTop: "20px" }}>
                Work in Progress
              </Typography.Title>
              <Typography.Text type="secondary">
                We're currently working on this page. Stay tuned for updates!
              </Typography.Text>
            </Col>
          </Row>
        ) : (
          <Card cover={<img alt="otp" src={otp_img} />}>
            <Typography.Title level={3}>Verification</Typography.Title>
            <p>Please enter your mobile number or email address.</p>
            <Form onFinish={submit} name="basic" layout="vertical">
              <Form.Item
                label="BD mobile number/Email address"
                name="contact"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter a valid mobile number or email address",
                  },
                ]}
              >
                <Input
                  placeholder="e.g., 017XXXXXXXX or example@email.com"
                  disabled={otpSent}
                />
              </Form.Item>
              {otpSent && (
                <React.Fragment>
                  <Form.Item
                    label="OTP"
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please enter verification code",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </React.Fragment>
              )}
              {otpSent && otpCountdown > 0 && (
                <OtpTimer
                  seconds={otpCountdown}
                  text="Enter Verification Code within "
                  ButtonText="Resend Code"
                  background={"#1150ff"}
                  resend={resendOTP}
                />
              )}
              <br />
              <Form.Item>
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  &nbsp;{!otpSent ? `Send OTP` : `Verify`}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </div>
    </Content>
  );
};

export default MobileVerification;
