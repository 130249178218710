//user only
export const SEND_OTP = "generateOtp";
export const VERIFY_OTP = "verifyOtp";

//agent only
export const AGENT_LOGIN = "agentLogin";
export const FORM_LIST = "agent/application/list";
export const AGENT_PROFILE = "agent/profile";
export const APPLICATION_DETAILS = "agent/application/details";

//common
export const GET_BRANCHES = "branch";
export const GET_FORM_TYPES = "form-types";

//mixed
export const GET_FORM_FIELDS = (form_id, user_type, sub_account_type) => {
  return `${user_type}/form/${form_id}/details?platform_type=web&sub_account_type=${sub_account_type}`;
  // return `${user_type}/form/${form_id}/details?platform_type=web`;
};
export const GENERATE_TRACKING_ID = (user_type) => {
  return `${user_type}/application/generateRequestTrackingId`;
};
export const UPLOAD_DOCUMENTS = (user_type) => {
  return `${user_type}/application/media`;
};
export const SUBMIT_FORM_SECTION = (user_type) => {
  if (user_type === "agent") {
    return `${user_type}/application/${user_type}/submit`;
  } else {
    return `${user_type}/application/submit`;
  }
};
export const SUBMIT_FORM_CONFIRM = (user_type) => {
  return `${user_type}/application/submit/confirm`;
};
export const APPLICATION_FORM_PREVIEW = () => {
  return `user/application/preview`;
};

export const DRAFT_FORM_DATA = "user/form/exitsFormFetch";
