import React, { useState } from "react";
import { Result, Button, message, Layout, Modal, Progress } from "antd";
import { postData, getData } from "../../../utils/api-service";
import { useHistory } from "react-router-dom";
import {
  SUBMIT_FORM_CONFIRM,
  APPLICATION_FORM_PREVIEW,
} from "../../../utils/api-list";
import Steps from "../../../components/Steps";
import FormPreview from "../FormPreview";
import "./confirmation.scss";

const Confirmation = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  // Props
  const { userType, prevForm, sequence } = props;

  // States
  const [User] = useState(JSON.parse(localStorage.getItem("User")));
  const [formSectionArray, setFormSectionArray] = useState(null);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);

  // Router
  const router = useHistory();

  const getPreviewFormData = async () => {
    setLoader(true);
    const platformType = "web";
    const trackingId = User?.request_tracking_uid;
    const baseQuery = APPLICATION_FORM_PREVIEW();
    const query = `${baseQuery}?platform_type=${platformType}&tracking_id=${trackingId}`;

    let response = await getData(query);

    if (response && response.code === 200) {
      let customArray = [];

      const pushTo = (item, groupName, sequenceId) => {
        customArray.push({
          value: item,
          ...(sequenceId && { new_sequence: sequenceId }),
          ...(groupName && { groupName: groupName }),
        });
      };

      Object.entries(response?.data?.formDetails).forEach(([key, value]) => {
        console.log("formDetails map", { key }, { value });
        switch (key) {
          case "sec_one":
            pushTo(value, "sec_one", 1);
            break;
          case "sec_two":
            pushTo(value, "sec_two", 2);
            break;
          case "sec_three":
            pushTo(value, "sec_three", 3);
            break;
            case "sec_four":
              pushTo(value, "sec_four", 3);
              break;
          default:
            pushTo(value);
            break;
        }
      });

      console.log({customArray})

      setFormSectionArray(customArray || null);
      message.success(response.message[0] || `Success getting form data`);
      setLoader(false);
    } else {
      message.error(response.message[0] || `Error getting form data`);
      setLoader(false);
    }
  };

  const showModal = () => {
    setVisible(true);
    getPreviewFormData();
  };

  const handleOk = () => {
    setLoader(true);
    confirmRegistration();
    setTimeout(() => {
      setLoader(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const confirmRegistration = async () => {
    setLoader(true);
    let query = SUBMIT_FORM_CONFIRM(userType);

    let confirmData = new FormData();
    confirmData.append("request_tracking_uid", User.request_tracking_uid);

    let response = await postData(query, confirmData);
    if (response && response.code === 200) {
      const score = response?.data?.calculated_score || 0;
      const messageText = `${response.message[0]}. Risk Grading: ${score}`;
      messageApi.open({
        type: "success",
        content: messageText,
        duration: 180,
      });
      // Introduce a 3-minute delay before hiding the loader and redirecting
      setLoader(false);
      setTimeout(() => {
        router.push("/");
      }, 180000); // 180000 milliseconds = 3 minutes
    } else {
      message.success(response.message[0]);
      setLoader(false);
    }
  };

  return (
    <Layout.Content>
      {contextHolder}
      <div className="confirmation-container">
        <Steps currentPage={sequence} />
        <Result
          status="success"
          title={`Your registration is almost complete!`}
          subTitle={`Your Request ID: ${User.request_tracking_uid}, Please Confirm your submission`}
          extra={[
            <Button onClick={showModal} size="large" type="dashed">
              Preview
            </Button>,
            <Button onClick={() => prevForm()} size="large">
              Go Back
            </Button>,
          ]}
          icon={
            <Progress
              type="circle"
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              percent={90}
            />
          }
        />
        <Modal
          visible={visible}
          title="Form Preview"
          onOk={handleOk}
          onCancel={handleCancel}
          width={`85%`}
          style={{
            top: 20,
          }}
          footer={[
            <Button key="back" onClick={() => prevForm()}>
              Go Back
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loader}
              onClick={handleOk}
            >
              Confirm
            </Button>,
            <Button loading={loader} onClick={handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <FormPreview formSectionArray={formSectionArray} loader={loader} />
        </Modal>
      </div>
    </Layout.Content>
  );
};

export default Confirmation;
