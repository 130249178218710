import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Radio, Button, Typography, Card, Spin, message } from "antd";
import { SettingTwoTone } from "@ant-design/icons";
import _ from "lodash";

import { postData, base_image_url } from "../../utils/api-service";
import { GET_FORM_FIELDS, GENERATE_TRACKING_ID } from "../../utils/api-list";
import dummyIcon from "../../assets/icon/money_4.png";
import "./AccountTypes.scss";

const AccountTypes = (props) => {
  const {
    match: { params },
  } = props;
  const [currentOptions, setCurrentOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    id: 8,
    account_type: 1,
    type_code: "savings_account",
    title: "Regular eKyc >= 10 Lakh",
    icon: "images/formSettings/icon/1602479227.png",
    color: null,
    description: null,
    is_active: 1,
    form: [
      {
        id: 8,
        form_type_code: "savings_account",
        kyc_type: "simplified",
      },
      {
        id: 11,
        form_type_code: "savings_account",
        kyc_type: "regular",
      },
    ],
  });
  const [formOptions, setFormOptions] = useState([]);
  const [selectedFormOption, setSelectedFormOption] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const router = useHistory();
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const sub_account_type = queryParams.get("sub_account_type");

  useEffect(() => {
    initializeState(params.accountType);
  }, []);

  const fetchForms = async (form_id) => {
    setLoader(true);

    let query = `${GET_FORM_FIELDS(
      form_id,
      params.userType,
      sub_account_type
    )}`;

    let data = new FormData();
    data.append("form_id", form_id);
    data.append("platform_type", "web");

    let response1 = await postData(query, data);

    if (response1 && response1.code === 200) {

      let response = {
        ...response1,
        data: {
          ...response1.data,
          form_section: response1.data.form_section.map((item) => {
            if (
              item.name === "Personal Information" ||
              item.name === "Present Address" ||
              item.name === "Permanent Address"
            ) {
              return {
                ...item,
                section_type: "info_form",
              };
            } else return item;
          }),
        },
      };

      console.log("response Account types", {response}, {response1})



      const nomineeDataArray = response?.data?.form_section?.filter(
        (sectionItem) => sectionItem.section_type === "both_form"
      );
      const personalInfoDataArray = response?.data?.form_section?.filter(
        (sectionItem) => sectionItem.section_type === "info_form"
      );

      const transactionProfile = {
        id: 12,
        form_id: 8,
        name: "Transaction Profile",
        form_platform_type: "WEB",
        section_attr_id: null,
        section_class: null,
        api_endpoint: null,
        should_validated: 1,
        validation_api_url: null,
        sequence: 3,
        carry_forward_data: 0,
        verification_type: "READ_NID_BASIC",
        section_type: "basic_form",
        is_preview_on: 1,
        can_go_previous_step: 1,
        is_show_on_tab: 1,
        form_section_fields: {
          deposits: [
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Deposits",
              placeholder: "Probable Monthly Number of Deposits",
              sub_section: "Deposit",
              row_title: "cash_deposit",
              field_name: "deposit_cash_deposit_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Deposit",
              placeholder: "Monthly Total Amount of Deposit",
              sub_section: "Deposit",
              row_title: "cash_deposit",
              field_name: "deposit_cash_deposit_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Deposit",
              placeholder: "Maximum Amount of Single Deposit",
              sub_section: "Deposit",
              row_title: "cash_deposit",
              field_name: "deposit_cash_deposit_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Cash Deposits Monthly Number",
              placeholder: "Cash Monthly Number of Deposits",
              sub_section: "Deposit",
              row_title: "deposit_through_transfer_or_instruments",
              field_name:
                "deposit_deposit_through_transfer_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Deposit",
              placeholder: "Monthly Total Amount of Deposit",
              sub_section: "Deposit",
              row_title: "deposit_through_transfer_or_instruments",
              field_name: "deposit_deposit_through_transfer_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Deposit",
              placeholder: "Maximum Amount of Single Deposit",
              sub_section: "Deposit",
              row_title: "deposit_through_transfer_or_instruments",
              field_name: "deposit_deposit_through_transfer_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 273,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Deposits",
              placeholder: "Probable Monthly Number of Deposits",
              sub_section: "Deposit",
              row_title: "foreign_inward_remittance",
              field_name:
                "deposit_foreign_inward_remittance_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 274,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Deposits",
              placeholder: "Monthly Total Amount of Deposits",
              sub_section: "Deposit",
              row_title: "foreign_inward_remittance",
              field_name: "deposit_foreign_inward_remittance_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 275,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Deposit",
              placeholder: "Maximum Amount of Single Deposit",
              sub_section: "Deposit",
              row_title: "foreign_inward_remittance",
              field_name:
                "deposit_foreign_inward_remittance_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 267,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Deposits",
              placeholder: "Probable Monthly Number of Deposits",
              sub_section: "Deposit",
              row_title: "export_proceeds",
              field_name: "deposit_export_proceeds_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 268,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Deposits",
              placeholder: "Monthly Total Amount of Deposits",
              sub_section: "Deposit",
              row_title: "export_proceeds",
              field_name: "deposit_export_proceeds_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 269,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Deposit",
              placeholder: "Maximum Amount of Single Deposit",
              sub_section: "Deposit",
              row_title: "export_proceeds",
              field_name: "deposit_export_proceeds_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 267,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Deposits",
              placeholder: "Probable Monthly Number of Deposits",
              sub_section: "Deposit",
              row_title: "deposit_or_transfer_from_bo_account",
              field_name:
                "deposit_deposit_or_transfer_from_bo_account_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 268,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Deposits",
              placeholder: "Monthly Total Amount of Deposits",
              sub_section: "Deposit",
              row_title: "deposit_or_transfer_from_bo_account",
              field_name:
                "deposit_deposit_or_transfer_from_bo_account_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 269,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Deposit",
              placeholder: "Maximum Amount of Single Deposit",
              sub_section: "Deposit",
              row_title: "deposit_or_transfer_from_bo_account",
              field_name:
                "deposit_deposit_or_transfer_from_bo_account_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 267,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Deposits",
              placeholder: "Probable Monthly Number of Deposits",
              sub_section: "Deposit",
              row_title: "others",
              field_name: "deposit_others_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 268,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Deposits",
              placeholder: "Monthly Total Amount of Deposits",
              sub_section: "Deposit",
              row_title: "others",
              field_name: "deposit_others_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 269,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Deposit",
              placeholder: "Maximum Amount of Single Deposit",
              sub_section: "Deposit",
              row_title: "others",
              field_name: "deposit_others_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
          ],
          withdrawals: [
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Withdrawals",
              placeholder: "Probable Monthly Number of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "cash_withdrawals",
              field_name: "withdrawal_cash_withdrawals_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Withdrawals",
              placeholder: "Monthly Total Amount of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "cash_withdrawals",
              field_name: "withdrawal_cash_withdrawals_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Withdrawal",
              placeholder: "Maximum Amount of Single Withdrawal",
              sub_section: "Withdrawals",
              row_title: "cash_withdrawals",
              field_name: "withdrawal_cash_withdrawals_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Withdrawals",
              placeholder: "Probable Monthly Number of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "withdrawals_through_transfer_or_instruments",
              field_name:
                "withdrawal_withdrawals_through_transfer_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Withdrawals",
              placeholder: "Monthly Total Amount of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "withdrawals_through_transfer_or_instruments",
              field_name:
                "withdrawal_withdrawals_through_transfer_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 263,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Withdrawal",
              placeholder: "Maximum Amount of Single Withdrawal",
              sub_section: "Withdrawals",
              row_title: "withdrawals_through_transfer_or_instruments",
              field_name:
                "withdrawal_withdrawals_through_transfer_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 273,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Withdrawals",
              placeholder: "Probable Monthly Number of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "withdrawal_of_foreign_outward_remittance",
              field_name:
                "withdrawal_withdrawal_of_foreign_outward_remittance_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 274,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Withdrawals",
              placeholder: "Monthly Total Amount of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "withdrawal_of_foreign_outward_remittance",
              field_name:
                "withdrawal_withdrawal_of_foreign_outward_remittance_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 275,
              sequence: 6,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Withdrawal",
              placeholder: "Maximum Amount of Single Withdrawal",
              sub_section: "Withdrawals",
              row_title: "withdrawal_of_foreign_outward_remittance",
              field_name:
                "withdrawal_withdrawal_of_foreign_outward_remittance_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 267,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Withdrawals",
              placeholder: "Probable Monthly Number of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "import_payment",
              field_name: "withdrawal_import_payment_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 268,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Withdrawals",
              placeholder: "Monthly Total Amount of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "import_payment",
              field_name: "withdrawal_import_payment_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 269,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Withdrawal",
              placeholder: "Maximum Amount of Single Withdrawal",
              sub_section: "Withdrawals",
              row_title: "import_payment",
              field_name: "withdrawal_import_payment_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 267,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Withdrawals",
              placeholder: "Probable Monthly Number of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "deposit_or_transfer_to_bo_account",
              field_name:
                "withdrawal_deposit_or_transfer_to_bo_account_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 268,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Withdrawals",
              placeholder: "Monthly Total Amount of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "deposit_or_transfer_to_bo_account",
              field_name:
                "withdrawal_deposit_or_transfer_to_bo_account_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 269,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Withdrawal",
              placeholder: "Maximum Amount of Single Withdrawal",
              sub_section: "Withdrawals",
              row_title: "deposit_or_transfer_to_bo_account",
              field_name:
                "withdrawal_deposit_or_transfer_to_bo_account_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 267,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Probable Monthly Number of Withdrawals",
              placeholder: "Probable Monthly Number of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "others",
              field_name: "withdrawal_others_probable_monthly_nod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 268,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Monthly Total Amount of Withdrawals",
              placeholder: "Monthly Total Amount of Withdrawals",
              sub_section: "Withdrawals",
              row_title: "others",
              field_name: "withdrawal_others_monthly_total_aod",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
            {
              id: 269,
              sequence: 7,
              field_type: "input",
              data_type: "number",
              label: "Maximum Amount of Single Withdrawal",
              placeholder: "Maximum Amount of Single Withdrawal",
              sub_section: "Withdrawals",
              row_title: "others",
              field_name: "withdrawal_others_maximum_amount_osd",
              field_default_value: null,
              is_required: 1,
              is_disabled: 0,
              is_readonly: 0,
            },
          ],
        },
      };

      const completeFormData = [
        ...response?.data?.form_section?.filter(
          (sectionItem, index) => sectionItem.is_show_on_tab
          // &&
          //   sectionItem?.name !== "Transaction Profile"
        ),
      ];

      // Insert at the 5th index
      // completeFormData.splice(2, 0, transactionProfile);

      // console.log("completeFormData ex", completeFormData)

      localStorage.setItem("complete_form", JSON.stringify(completeFormData));
      localStorage.setItem("nominee_data", JSON.stringify(nomineeDataArray));
      localStorage.setItem(
        "personal_info_data",
        JSON.stringify(personalInfoDataArray)
      );
      fetchTrackingId(form_id);
    } else {
      setLoader(false);
      message.error("Something went wrong please try again");
    }
  };

  const fetchTrackingId = async (form_id) => {
    let query = `${GENERATE_TRACKING_ID(params.userType)}`;

    let trackingData = new FormData();
    trackingData.append("form_id", form_id);
    trackingData.append("platform_type", "web");

    let response = await postData(query, trackingData);

    if (response && response.code === 200) {
      let User = JSON.parse(localStorage.getItem("User"));
      User.request_tracking_uid = response.data.request_tracking_uid;
      User.form_id = response.data.form_id;
      User.account_type = null;
      localStorage.setItem("User", JSON.stringify(User));
      router.push(`/${params?.userType}/application-form?sub_account_type=${sub_account_type}`);
      setLoader(false);
    } else {
      message.error("Something went wrong");
      setLoader(false);
    }
  };

  const initializeState = (accountType) => {
    let options = JSON.parse(localStorage.getItem(`${accountType}`));
    if (_.isEmpty(options)) {
      return (window.location.href = "/");
    }
    setCurrentOptions(options.form_type);
    setPageLoader(false);
  };

  const onChangeRadio = (event) => {
    console.log("onChangeRadio", event.target.value);
    setSelectedValue(event.target.value);
  };

  const formOptionChange = (event) => {
    setSelectedValue("");
    setSelectedFormOption(event.target.value);
  };

  const next = () => {
    if (_.isEmpty(selectedValue)) {
      message.error("Please select an Account Type");
      return;
    }
    if (params.userType === "user") {
      if (_.find(selectedValue.form, ["kyc_type", "simplified"])) {
        let form_id = _.find(selectedValue.form, ["kyc_type", "simplified"]).id;
        fetchForms(form_id);
      } else {
        message.error("No form avalible for this Account Type");
      }
    } else if (params.userType === "agent") {
      if (!_.isEmpty(selectedValue.form)) {
        setFormOptions(selectedValue.form);
      } else {
        message.error("No form avalible for this Account Type");
      }
    }
  };

  const agentNext = () => {
    if (_.isEmpty(selectedFormOption)) {
      message.error("Please select a Form Type");
      return;
    }
    fetchForms(selectedFormOption.id);
  };

  const clearFormType = () => {
    setFormOptions([]);
  };

  return (
    <Layout.Content>
      {pageLoader ? (
        <div className="acc-types-container">
          <Spin
            indicator={<SettingTwoTone style={{ fontSize: 50 }} spin />}
            style={{ marginTop: "150px" }}
            tip="Loading...."
          />
        </div>
      ) : (
        <div className="acc-types-container">
          <br />
          <br />
          {_.isEmpty(formOptions) ? (
            <Card>
              <Typography.Title level={3}>
                Select an Account Type
              </Typography.Title>
              <br />
              <Radio.Group
                onChange={(e) => onChangeRadio(e)}
                defaultValue="a"
                buttonStyle="solid"
              >
                {currentOptions &&
                  currentOptions.map((opt, index) => {
                    return (
                      <Radio.Button value={opt} key={index}>
                        <img
                          src={
                            opt.icon
                              ? `${base_image_url}${opt.icon}`
                              : dummyIcon
                          }
                          alt=""
                        />
                        <Typography.Text>{opt.title}</Typography.Text>
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
              <br />
              <Button
                size="large"
                block
                type="primary"
                loading={loader}
                style={{ marginTop: "20px" }}
                onClick={next}
              >
                &nbsp;Next Step
              </Button>
            </Card>
          ) : (
            <Card>
              <Typography.Title level={3}>Select Form Type</Typography.Title>
              <br />
              <Radio.Group
                onChange={(e) => formOptionChange(e)}
                defaultValue="a"
                buttonStyle="solid"
              >
                {formOptions &&
                  formOptions.map((opt, index) => {
                    return (
                      <Radio.Button value={opt} key={index}>
                        <img
                          src={
                            formOptions.icon
                              ? `${base_image_url}${formOptions.icon}`
                              : dummyIcon
                          }
                          alt=""
                        />
                        <Typography.Text>
                          {opt.kyc_type === "simplified"
                            ? "Simplified Form"
                            : "Regular Form"}
                        </Typography.Text>
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
              <br />
              <Button
                size="large"
                block
                type="primary"
                loading={loader}
                style={{ marginTop: "20px" }}
                onClick={agentNext}
              >
                &nbsp;Next Step
              </Button>
              <Button
                size="large"
                block
                // type="Secondary"
                style={{ marginTop: "20px" }}
                onClick={clearFormType}
              >
                Back
              </Button>
            </Card>
          )}
        </div>
      )}
    </Layout.Content>
  );
};

export default AccountTypes;
