import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Typography, Button, Dropdown, Menu, message } from "antd";
import cookies from "js-cookie";
import { UserOutlined } from "@ant-design/icons";
// import Logo from "../../assets/images/mainlogo.png";
import Logo from "../../assets/images/nrb-connect.jpg";

import "./Header.scss";

const { Header } = Layout;

const Head = () => {
  const router = useHistory();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState();
  const logout = () => {
    cookies.remove("token");
    cookies.remove("agent");
    message.success("Logged out");
    setLoggedIn(null);
    router.push("/");
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => router.push("/agent/dashboard")} key="1">
        Dashboard
      </Menu.Item>
      <Menu.Item onClick={logout} key="2">
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (cookies.get("token") && cookies.get("agent")) {
      const user_info = JSON.parse(cookies.get("agent"));
      setLoggedIn(user_info?.name || "Agent");
    }
  }, [location]);

  return (
    <Header className="header-wrapper">
      <div onClick={() => router?.push("/")} className="logo">
        <img   src={Logo} alt="logo" />
      </div>
      <div>
        {loggedIn && (
          <Dropdown overlay={menu} placement="bottomCenter">
            <Button icon={<UserOutlined />}>{loggedIn}</Button>
          </Dropdown>
        )}
        {/* {!loggedIn && (
          <Button
            type="primary-outlined"
            onClick={() => router?.push(`/agent/login`)}
          >
            Agent Login
          </Button>
        )} */}
      </div>
    </Header>
  );
};

export default Head;
