import { SettingTwoTone } from "@ant-design/icons";
import { Button, Card, Layout, Spin, Typography } from "antd";
import cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import defaultIcon from "src/assets/icon/bank_1.png";
import { GET_FORM_TYPES } from "src/utils/api-list";
import { base_image_url, getData } from "src/utils/api-service";
import "./Home.scss";

const { Content } = Layout;

const SubType = () => {
  const router = useHistory();
  const [homeMenu, setHomeMenu] = useState([]);
  const [loader, setLoader] = useState(true);
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const accountType = queryParams.get("type");

  useEffect(() => {
    // localStorage.clear();
    fetchHomeData();
  }, []);

  const fetchHomeData = async () => {
    let accountTypeData = JSON.parse(localStorage.getItem(accountType));
    setHomeMenu(accountTypeData);
    setLoader(false);
  };

  const selectOption = (example) => {
    console.log("example", example);
    let item = {
      ...example,
      sub_type: [
        {
          id: 3,
          account_type: 1,
          type_code: "obu_ac",
          title: "OBU A/C",
          icon: null,
          color: "grey",
          description: "OBU A/C",
          is_active: 1,
          form: [],
        },
        {
          id: 4,
          account_type: 1,
          type_code: "ib_ac",
          title: "IB A/C",
          icon: null,
          color: null,
          description: "IB A/C",
          is_active: 1,
          form: [],
        },
      ],
    };
    // const user = {
    //   account_type: item.account_type_title.toLowerCase().split(" ").join("_"),
    //   fixed: {},
    // };
    if (cookies.get("token")) {
      //   router.push(`/agent/${user.account_type}`);
      //   localStorage.setItem("User", JSON.stringify(user));
    } else {
      //   localStorage.setItem("User", JSON.stringify(user));
      router.push(
        `/user/mobile-verification?type=${accountType}&sub_account_type=${example?.type_code}`
      );
    }
  };

  return (
    <Content>
      {loader ? (
        <div className="home-container">
          <Spin
            indicator={<SettingTwoTone style={{ fontSize: 50 }} spin />}
            style={{ marginTop: "150px" }}
            tip="Loading...."
          />
        </div>
      ) : (
        <div className="home-container">
          <Typography.Title level={1}>Welcome</Typography.Title>
          <p>Start your offshore banking</p>
          <div className="card-section">
            {homeMenu?.sub_type &&
              homeMenu?.sub_type.map((item) => {
                return (
                  <Card key={item.id} hoverable>
                    <div className="icon-img">
                      <img
                        src={`${base_image_url}${item.icon}` || defaultIcon}
                        alt="Default Icon"
                      />
                    </div>
                    <Typography.Title level={3}>{item.title}</Typography.Title>
                    <p>
                      {item.description ||
                        `Click here to open ${item.description}`}
                    </p>
                    <br />
                    <Button
                      size="large"
                      block
                      type="primary"
                      value={item}
                      onClick={() => selectOption(item)}
                    >
                      Get Started
                    </Button>
                  </Card>
                );
              })}
          </div>
        </div>
      )}
    </Content>
  );
};

export default SubType;
