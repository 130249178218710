import React from "react";
import "./tableStyle.css";
import TxnProfileEnum from "src/utils/txn-profile.enum";

const DepositTable = ({ formFieldData }) => {
  const depositTitles = Object.keys(formFieldData);

  // Calculate totals for each column
  const totals = depositTitles.reduce(
    (acc, title) => {
      acc[0] += parseInt(formFieldData[title][0].user_input_value, 10) || 0; // Column 1
      acc[1] += parseInt(formFieldData[title][1].user_input_value, 10) || 0; // Column 2
      acc[2] += parseInt(formFieldData[title][2].user_input_value, 10) || 0; // Column 3
      return acc;
    },
    [0, 0, 0] // Initial totals for each column
  );

  return (
    <table className="transactionTable">
      <tr>
        <th className="headline">Particulars</th>
        <th className="headline">Maximum Amount of Single Deposit</th>
        <th className="headline">Probable Monthly Number of Deposits</th>
        <th className="headline">Monthly Total Amount of Deposits</th>
      </tr>
      {depositTitles.map((title, index) => (
        <tr key={index}>
          <td>{TxnProfileEnum[title]}</td>
          <td>{formFieldData[title][0].user_input_value}</td>
          <td>{formFieldData[title][1].user_input_value}</td>
          <td>{formFieldData[title][2].user_input_value}</td>
        </tr>
      ))}
      <tr>
        <td>
          <b>Total</b>
        </td>
        <td>
          <b>{totals[0]}</b>
        </td>
        <td>
          <b>{totals[1]}</b>
        </td>
        <td>
          <b>{totals[2]}</b>
        </td>
      </tr>
    </table>
  );
};

export default DepositTable;
