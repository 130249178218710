import React from "react";
import { base_image_url } from "../../../utils/api-service";
import { Image, Empty, Spin, Card, Form, Input, Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import DepositTable from "./DepositTable/index.js";
import WithdrawelTable from "./WithdrawelTable/index.js";

const FormPreview = (props) => {
  // Props
  const { formSectionArray, loader } = props;

  const getFormItem = (formFieldItem, fieldIndex) => {
    if (
      formFieldItem?.data_type === "file" ||
      formFieldItem?.data_type === "image"
    ) {
      return (
        <Col xs={12} md={12} style={{ padding: "0 10px" }} key={uuidv4()}>
          <Row>
            <Col xs={8} md={8}>
              <label>{formFieldItem?.label}</label>
            </Col>
            <Col xs={16} md={16}>
              <Form.Item>
                <Image
                  width={100}
                  height={100}
                  src={`${base_image_url}${formFieldItem?.user_input_value}`}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      );
    } else {
      return (
        <Col xs={12} md={12} style={{ padding: "0 10px" }} key={uuidv4()}>
          <Row>
            <Col xs={8} md={8}>
              <label>{formFieldItem?.label}</label>
            </Col>
            <Col xs={16} md={16}>
              <Form.Item>
                <Input
                  disabled
                  value={formFieldItem?.user_input_value}
                  style={{ color: "black" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      );
    }
  };

  const formSectionRender = (formSection, formSectionIndex) => {
    console.log("formSectionRender", formSection, formSectionIndex);
    if (formSection?.Deposit?.cash_deposit) {
      return (
        <Card
          title={<b>Transaction Profile</b>}
          key={formSectionIndex}
          style={{ marginBottom: "10px" }}
        >
          <Row>
            <div style={{ margin: "auto auto" }}>
              <h3> Deposit</h3>
            </div>
            <DepositTable formFieldData={formSection.Deposit} />
          </Row>
          <Row>
            <div style={{ margin: "auto auto" }}>
              <h3> Withdrawal</h3>
            </div>
            <WithdrawelTable formFieldData={formSection.Withdrawals} />
          </Row>
        </Card>
      );
    } else {
      return (
        <Card
          title={<b>{formSection?.name}</b>}
          key={formSectionIndex}
          style={{ marginBottom: "10px" }}
        >
          <Row>
            {formSection?.form_section_fields?.map(
              (formFieldItem, fieldIndex) =>
                getFormItem(formFieldItem, fieldIndex)
            )}
          </Row>
        </Card>
      );
    }
  };

  if (!formSectionArray) {
    if (loader) {
      return <Spin />;
    } else {
      return <Empty />;
    }
  }

  return (
    <>
      {formSectionArray?.map((formGroup, formGroupIndex) => {
        return (
          <Card key={formGroupIndex} style={{ marginBottom: "10px" }}>
            {formGroup?.value?.map((formSection, formSectionIndex) =>
              formSectionRender(formSection, formSectionIndex)
            )}
          </Card>
        );
      })}
    </>
  );
};

export default FormPreview;
