import React, { useEffect, useState } from "react";
import {
  Layout,
  Form,
  Button,
  Typography,
  message,
  Row,
  Col,
  Card,
  Table,
  Input,
} from "antd";
import _ from "lodash";

import Steps from "../../Steps";
import FormField from "../FormField";
import { postData } from "../../../utils/api-service";
import { SUBMIT_FORM_SECTION, UPLOAD_DOCUMENTS } from "../../../utils/api-list";
import { dateFormat } from "../../../utils/constants";
import "./BasicForm.scss";
import TxnProfileEnum from "src/utils/txn-profile.enum";
import { useLocation } from "react-router-dom";
import { generateKey } from "src/utils/util-functions";

const { Content } = Layout;

function InfoForm({
  formSection,
  userType,
  nextForm,
  prevForm,
  sequence,
  setSelectedForm,
}) {
  const location = useLocation();
  console.log("component", "infoForm");
  const [loader, setLoader] = useState(false);
  const [User] = useState(JSON.parse(localStorage.getItem("User")));
  const [uploadList, setUploadList] = useState([]);
  const [personalInfoData] = useState(
    JSON.parse(localStorage.getItem("personal_info_data"))
  );

  const queryParams = new URLSearchParams(location.search);
  const subAccountType = queryParams.get("sub_account_type");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let fieldWidth = "200px";
  let fieldMargin = "10px";
  if (screenWidth < 700) {
    fieldWidth = "70px";
    fieldMargin = "auto 3px";
  } else if (screenWidth < 890) {
    fieldWidth = "100px";
    fieldMargin = "auto 8px";
  } else if (screenWidth < 1120) {
    fieldWidth = "150px";
    fieldMargin = "auto 10px";
  }

  console.log("formSection", formSection);

  const onFinished = async (values) => {
    console.log("onFinished", values);
    setLoader(true);
    if (!_.isEmpty(uploadList)) {
      let upload_complete = await fileUpload();
      if (upload_complete) sectionSubmission(values);
      else return;
    } else sectionSubmission(values);
  };

  const sectionSubmission = async (values) => {
    console.log({values})
    let requestBody = {
      form_section: [],
    };
    let section_field_data = [];

    // Flatten nested `form_section_fields`
    let flattenFields = personalInfoData.flatMap(section => section.form_section_fields);


    console.log({flattenFields}, personalInfoData[1]);

    // Map through the values and process each field
    _.mapKeys(values, (value, key) => {
      const fieldData = _.find(flattenFields, { field_name: key });

      if (fieldData) {
        if (fieldData.data_type === "file") {
          // File handling (already handled elsewhere, skip here if necessary)
        } else if (fieldData.data_type === "date") {
          section_field_data.push({
            field_id: fieldData.id,
            field_value: value ? value.format(dateFormat) : null,
          });
        } else {
          section_field_data.push({
            field_id: fieldData.id,
            field_value: value,
          });
        }
      }
    });
    requestBody.form_id = formSection.form_id;
    requestBody.request_tracking_uid = User.request_tracking_uid;
    requestBody.form_section.push({
      section_id: formSection.id,
      section_field: section_field_data,
    });

    let query = SUBMIT_FORM_SECTION(userType);

    let response = await postData(query, requestBody);

    if (response && response.code === 200) {
      _.mapKeys(values, (value, key) => {
        User[`${key}`] = value;
      });
      localStorage.setItem("User", JSON.stringify(User));
      message.success(response.message[0]);
      setLoader(false);
      setUploadList([]);
      nextForm();
    } else {
      message.error(response.message[0]);
      setLoader(false);
    }
  };

  const fileUpload = async () => {
    let uploadData = new FormData();
    uploadData.append("form_id", formSection.form_id);
    uploadData.append("request_tracking_uid", User.request_tracking_uid);
    if (!!formSection.should_validated) {
      uploadData.append("verification_type", formSection.verification_type);
    }
    uploadList.forEach((item, index) => {
      uploadData.append(`form[${index}][section_id]`, formSection.id);
      uploadData.append(`form[${index}][field_id]`, item.field_id);
      uploadData.append(`form[${index}][field_value]`, item.field_value);
    });

    let query = UPLOAD_DOCUMENTS(userType);
    let uploadResponse = await postData(query, uploadData);
    if (uploadResponse && uploadResponse.code === 200) {
      message.success(uploadResponse.message[0]);
      return true;
    } else {
      message.error("something went wrong please try again");
      return false;
    }
  };

  const fileHandle = async (event) => {
    if (event.target.files[0])
      setUploadList((prevState) => [
        ...prevState,
        {
          field_id: event.target.id,
          field_value: event.target.files[0],
        },
      ]);
    else message.error("Please select a valid file");
  };

  function groupFieldsByRowTitle(fields) {
    if (Array.isArray(fields)) {
      return fields.reduce((acc, field) => {
        acc[field.row_title] = acc[field.row_title] || [];
        acc[field.row_title].push(field);
        return acc;
      }, {});
    } else {
      return {};
    }
  }

  // Process the new data structure
  function processFormSectionFields(formSectionFields) {
    const groupedFields = {};
    Object.entries(formSectionFields).forEach(([subSection, rowGroups]) => {
      groupedFields[subSection] = {};
      Object.entries(rowGroups).forEach(([rowTitle, fields]) => {
        groupedFields[subSection][rowTitle] = fields;
      });
    });
    return groupedFields;
  }

  const groupedFields = processFormSectionFields(
    formSection.form_section_fields
  );
  console.log("groupedFields", groupedFields);
  // Generate columns and table data dynamically
  const tables =
    formSection &&
    formSection.form_section_fields &&
    !Array.isArray(formSection.form_section_fields) &&
    formSection?.name === "Transaction Profile"
      ? Object.entries(
          processFormSectionFields(formSection.form_section_fields)
        ).map(([subSection, group]) => {
          const firstRowFields = Object.values(group)[0] || [];
          const columnTitles = firstRowFields.map((field) => field.label);

          const columns = [
            {
              title: "Particulars",
              dataIndex: "rowTitle",
              key: "rowTitle",
            },
            ...columnTitles.map((label, index) => ({
              title: label,
              dataIndex: `field${index + 1}`,
              key: `field${index + 1}`,
              render: (field, record) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <FormField
                    formSection={record.formSection}
                    field={{ ...field, label: null }}
                    fileHandle={record.fileHandle}
                    User={record.User}
                    style={{
                      flex: 1,
                      height: "100%",
                      width: fieldWidth,
                      margin: fieldMargin,
                    }}
                  />
                </div>
              ),
              onCell: () => ({
                style: { padding: 3, height: "100%" },
              }),
            })),
          ];

          const tableData = Object.entries(group).map(
            ([rowTitle, fields], index) => {
              return {
                key: index,
                rowTitle: TxnProfileEnum[rowTitle], // Adjust based on your label mapping if required
                ...fields.reduce((acc, field, i) => {
                  acc[`field${i + 1}`] = field;
                  return acc;
                }, {}),
                formSection,
                fileHandle,
                User,
              };
            }
          );

          return (
            <Table
              key={subSection}
              title={() => (
                <Typography.Title level={5}>{subSection}</Typography.Title>
              )}
              columns={columns}
              dataSource={tableData}
              pagination={false}
              bordered
              style={{ marginBottom: "24px" }}
            />
          );
        })
      : null;

  const [form] = Form.useForm();
  const [showCustomProfession, setShowCustomProfession] = useState(false);

  const handleFieldChange = (changedValues) => {
    console.log("changedValues", changedValues);
    const fieldName = Object.keys(changedValues)[0];
    const fieldValue = Object.values(changedValues)[0];
    console.log("profession field Value: ", fieldValue, fieldName);

    if (fieldName === "profession") {
      // Show custom input when the profession is "Other"
      setShowCustomProfession(fieldValue === "Other");
      if (fieldValue !== "Other") {
        // Reset custom profession field value if not "Other"
        form.setFieldsValue({ custom_profession: null });
      }
    }
  };

  return (
    <Content>
      <div className="personal-info-container">
        <Steps currentPage={sequence} />
        <Typography.Title style={{ maxWidth: "440px" }} level={3}>
          {formSection.name}
        </Typography.Title>
        <Card>
          <Form
            form={form}
            name="validate_other"
            layout="vertical"
            colon={false}
            onFinish={onFinished}
            onValuesChange={handleFieldChange}
          >
            <Row gutter={16}>
              {formSection &&
                formSection.form_section_fields &&
                formSection.form_section_fields.map((field, index) => {
                  return (
                    <FormField
                      formSection={formSection}
                      field={field}
                      fileHandle={fileHandle}
                      key={index}
                      index
                      User={User}
                      setSelectedForm={setSelectedForm}
                      isOptionOther={field?.field_name === "profession"}
                      // isOptionOther={field?.options?.some(option => option.value === "Other")}
                    />
                  );
                })}
            </Row>
            <div style={{ paddingTop: "40px" }} key={generateKey()}>
              <Row gutter={16} justify="center">
                <Typography.Title style={{ maxWidth: "340px" }} level={3}>
                  {personalInfoData[1]?.name}
                </Typography.Title>
              </Row>
              <Row gutter={16}>
                {personalInfoData?.length > 0 &&
                  personalInfoData[1].form_section_fields.map(
                    (field, index) => {
                      return (
                        <FormField
                          formSection={formSection}
                          field={field}
                          fileHandle={fileHandle}
                          key={index}
                          index
                          User={User}
                          setSelectedForm={setSelectedForm}
                          isOptionOther={field?.field_name === "profession"}
                          // isOptionOther={field?.options?.some(option => option.value === "Other")}
                        />
                      );
                    }
                  )}
              </Row>
            </div>
            <div style={{ paddingTop: "40px" }} key={generateKey()}>
              <Row gutter={16} justify="center">
                <Typography.Title style={{ maxWidth: "340px" }} level={3}>
                  {personalInfoData[2]?.name}
                </Typography.Title>
              </Row>
              <Row gutter={16}>
                {personalInfoData?.length > 0 &&
                  personalInfoData[2].form_section_fields.map(
                    (field, index) => {
                      return (
                        <FormField
                          formSection={formSection}
                          field={field}
                          fileHandle={fileHandle}
                          key={index}
                          index
                          User={User}
                          setSelectedForm={setSelectedForm}
                          isOptionOther={field?.field_name === "profession"}
                          // isOptionOther={field?.options?.some(option => option.value === "Other")}
                        />
                      );
                    }
                  )}
              </Row>
            </div>

            <Form.Item>
              <Row>
                <Col xs={6}>
                  <Button size="large" block onClick={() => prevForm()}>
                    Back
                  </Button>
                </Col>
                <Col md={{ span: 6, offset: 12 }} xs={{ span: 8, offset: 10 }}>
                  <Button
                    size="large"
                    loading={loader}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    &nbsp;Next Step
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Content>
  );
}

export default InfoForm;
