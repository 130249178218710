import React from "react";
import { Steps } from "antd";
import "./Steps.scss";

const { Step } = Steps;

const Stepper = ({ currentPage }) => {
  const width = window.innerWidth;
  const form = JSON.parse(localStorage.getItem("complete_form"));


  return (
      <Steps progressDot current={currentPage || 0}>
          {form.map((item, index) => {
              return <Step key={index} title={width > 600 && `${item.name}`} />;
          })}
          <Step title={width > 600 && "Confirmation"} />
      </Steps>
  );
};

export default Stepper;
