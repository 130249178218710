import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Divider,
  message,
  Typography,
  Spin,
  Button,
} from "antd";
import { SettingTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { APPLICATION_DETAILS } from "../../../utils/api-list";
import { postData, base_image_url } from "../../../utils/api-service";
import DefaultNID from "../../../assets/images/nid.png";
import "./user-details.scss";

const style = {
  padding: "8px 8px",
  marginBottom: "10px",
};

const loadingDiv = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const rowConfig = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

const UserDetails = ({ match }) => {
  const [applicationDetails, setApplicationDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const router = useHistory();

  useEffect(() => {
    (async () => {
      let formData = new FormData();
      formData.append("request_tracking_uid", match?.params?.details);
      formData.append("platform_type", "WEB");

      const res = await postData(APPLICATION_DETAILS, formData);
      if (res?.code === 200) {
        setApplicationDetails(res.data.formInfo);
        setLoader(false);
      } else {
        message.error(res.message);
        setLoader(false);
      }
    })();
  }, []);

  const renderContent = (field) => {
    if (
      field.mime_type === "image/jpeg" ||
      field.mine_type === "image/png" ||
      field.mine_type === "image/bmp" ||
      field.data_type === "file"
    ) {
      return (
        <img
          className="image-container"
          src={`${base_image_url}${field.user_input_value}` || DefaultNID}
          alt="Render Content"
        />
      );
    } else {
      return <p>{field.user_input_value}</p>;
    }
  };

  return (
    <Layout.Content>
      {loader ? (
        <div style={loadingDiv}>
          <Spin
            indicator={<SettingTwoTone style={{ fontSize: 50 }} spin />}
            style={{ marginTop: "150px" }}
            tip="Loading...."
          />
        </div>
      ) : (
        <div className="user-details-wrapper">
          {applicationDetails.form_section &&
            applicationDetails.form_section.map((section, index) => (
              <div key={index} className="space">
                <Divider orientation="left">
                  <Typography.Title level={3}>{section.name}</Typography.Title>
                </Divider>
                <Row gutter={rowConfig}>
                  {section.form_section_fields &&
                    section.form_section_fields.map((field, index) => (
                      <Col key={index} className="gutter-row" span={6}>
                        <div style={style}>
                          <Typography.Title level={5}>
                            {field.label}
                          </Typography.Title>
                          {renderContent(field)}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            ))}
          <div className="space">
            <Row
              style={{ marginRight: "20px" }}
              justify="end"
              gutter={rowConfig}
            >
              <Button
                onClick={() => router.push("/agent/dashboard")}
                type="primary"
                size="large"
              >
                Go to Dashboard
              </Button>
            </Row>
          </div>
        </div>
      )}
    </Layout.Content>
  );
};

export default UserDetails;
