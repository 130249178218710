import React, { useEffect, useState } from "react";
import { Layout, Button, Typography, Card, Row, Col, Tag } from "antd";
import DefaultNID from "../../../assets/images/nid.png";
import Steps from "../../../components/Steps";
import "./NIDVerified.scss";

const { Content } = Layout;
const { Title, Text } = Typography;

const NIDVerified = ({ nextForm, clearPreview, sequence }) => {
    const [User] = useState(JSON.parse(localStorage.getItem("User")));
    const [fixed, setFiexed] = useState([]);
    const NIDCompareScore =
        User?.compare_response?.object_detection_score || null;

    useEffect(() => {
        renderData();
    }, []);

    const renderData = () => {
        let fixed = [];
        for (const [key, value] of Object.entries(User.fixed)) {
            fixed.push({ key: key, value: value });
        }
        setFiexed(fixed);
    };

    const formatCharecter = (str) => {
        let splitStr = str.toLowerCase().split("_");
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
    };

    return (
        <Content>
            <div className="nid-verify-container">
                <Steps currentPage={sequence} />
                <Typography.Title level={3}>NID Verification</Typography.Title>
                <p>Your Photo and NID has been verified</p>
                <div className="nid-data-container space">
                    <Row gutter={5}>
                        <Col md={8} sm={24}>
                            <Card
                                className="flex-center"
                                style={{ height: "200px" }}
                            >
                                <div
                                    className="nid-img"
                                    style={{ width: "180px", height: "180px" }}
                                >
                                    <img
                                        src={User.user_photo || DefaultNID}
                                        alt=""
                                    />
                                </div>
                            </Card>
                        </Col>
                        <Col md={8} sm={24}>
                            <Card
                                className="flex-center"
                                style={{ height: "200px" }}
                            >
                                <div
                                    className="nid-img"
                                    style={{ width: "180px", height: "180px" }}
                                >
                                    <img
                                        src={User.nid_photo || DefaultNID}
                                        alt=""
                                    />
                                </div>
                            </Card>
                        </Col>
                        <Col md={8} sm={24}>
                            <Card
                                className="flex-center"
                                style={{ height: "200px" }}
                            >
                                <Row
                                    justify="space-between"
                                    align="top"
                                    className="score-container"
                                >
                                    <Col span={24}>
                                        <div className="flex-center">
                                            <Title level={5}>
                                                {" "}
                                                Matching Score{" "}
                                            </Title>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="flex-center nid-score-container">
                                            {User?.nid_image_match_score.toFixed(
                                                2
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="flex-center">
                                            {User?.nid_image_match_score &&
                                            User?.nid_image_match_score < 40 ? (
                                                <Tag
                                                    style={{ fontSize: 8 }}
                                                    color="red"
                                                >
                                                    {
                                                        "Matching Score must be greater than 40. Please try again."
                                                    }
                                                </Tag>
                                            ) : (
                                                <Tag
                                                    color={
                                                        User?.nid_verification_status
                                                            ? "green"
                                                            : "red"
                                                    }
                                                >
                                                    {User?.nid_verification_status
                                                        ? "Passed"
                                                        : "Failed"}
                                                </Tag>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={5}>
                        <Col md={24} sm={24}>
                            <Card>
                                <div
                                    style={{
                                        paddingLeft: "20%",
                                        paddingRight: "20%",
                                    }}
                                >
                                    <div className="flex-center">
                                        <Title level={5}> NID Details </Title>
                                    </div>
                                    <Text strong>Name: </Text>
                                    {User?.compare_response?.key_word_name ||
                                        ""}{" "}
                                    <br />
                                    <Text strong>Date of Birth: </Text>
                                    {User?.compare_response?.key_word_dob ||
                                        ""}{" "}
                                    <br />
                                    <Text strong>NID Number: </Text>
                                    {User?.compare_response?.key_word_nid_no ||
                                        ""}{" "}
                                    <br />
                                    <Text strong>Doc Detected: </Text>
                                    {User?.compare_response
                                        ?.final_verification_status || ""}{" "}
                                    <br />
                                    <Text strong>Face Detected: </Text>
                                    {User?.compare_response
                                        ?.final_verification_status || ""}{" "}
                                    <br />
                                    <Text strong>Image Readable: </Text>
                                    {User?.compare_response
                                        ?.final_verification_status || ""}{" "}
                                    <br />
                                    <Text strong>NID Score: </Text>
                                    {User?.nid_score
                                        ? User?.nid_score.toFixed(2)
                                        : "N/A"}{" "}
                                    <br />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <div className="flex-center" style={{ paddingTop: "10px" }}>
                        <Title level={5}> EC Details </Title>
                    </div>
                    <Row gutter={5}>
                        <Col md={12} sm={24}>
                            <Card style={{ height: "300px" }}>
                                <div className="flex-center">
                                    <Title level={5}> Bangla </Title>
                                </div>
                                <Text strong>Full Name: </Text>
                                {User?.fixed?.name_bn || ""} <br />
                                <Text strong>Father's Name: </Text>
                                {User?.fixed?.father_name || ""} <br />
                                <Text strong>Mother's Name: </Text>
                                {User?.fixed?.mother_name || ""} <br />
                                <Text strong>Date of Birth:</Text>
                                {User?.fixed?.date_of_birth || ""} <br />
                                <Text strong>Blood Group:</Text>
                                {User?.fixed?.blood_group || ""} <br />
                                <Text strong>NID Number:</Text>
                                {User?.fixed?.nid_number || ""} <br />
                                <Text strong>Present Address:</Text>
                                {User?.fixed?.present_address_full_bn ||
                                    ""}{" "}
                                <br />
                                <Text strong>Permanent Address:</Text>
                                {User?.fixed?.permanent_address_full_bn ||
                                    ""}{" "}
                                <br />
                            </Card>
                        </Col>
                        <Col md={12} sm={24}>
                            <Card style={{ height: "300px" }}>
                                <div className="flex-center">
                                    <Title level={5}> English </Title>
                                </div>
                                <Text strong>Full Name: </Text>
                                {User?.fixed?.name_en || ""} <br />
                                <Text strong>Father's Name: </Text>
                                {User?.fixed?.father_name_en || ""} <br />
                                <Text strong>Mother's Name: </Text>
                                {User?.fixed?.mother_name_en || ""} <br />
                                <Text strong>Date of Birth:</Text>
                                {User?.fixed?.date_of_birth || ""} <br />
                                <Text strong>Blood Group:</Text>
                                {User?.fixed?.blood_group || ""} <br />
                                <Text strong>NID Number:</Text>
                                {User?.fixed?.nid_number || ""} <br />
                                <Text strong>Present Address:</Text>
                                {User?.fixed?.present_address_full_en ||
                                    ""}{" "}
                                <br />
                                <Text strong>Permanent Address:</Text>
                                {User?.fixed?.permanent_address_full_en ||
                                    ""}{" "}
                                <br />
                            </Card>
                        </Col>
                    </Row>

                    <div className="space">
                        <Row>
                            <Col md={6}>
                                <Button
                                    size="large"
                                    block
                                    // type="secondary"
                                    onClick={() => clearPreview()}
                                >
                                    Back
                                </Button>
                            </Col>
                            <Col md={{ span: 6, offset: 12 }}>
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                    disabled={
                                        User?.nid_verification_status
                                            ? false
                                            : true
                                    }
                                    onClick={() => nextForm()}
                                >
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default NIDVerified;
